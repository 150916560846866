var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('h3', {
    staticClass: "mb-50 fw-700"
  }, [_vm._v(" Gửi email vé 1S cũ ")]), _c('b-form', {
    ref: "form"
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nhập vào PNR",
      "label-for": "pnr"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "pnr",
      "formatter": _vm.trimUpperCaseInput,
      "placeholder": "Nhập PNR..."
    },
    model: {
      value: _vm.pnr,
      callback: function callback($$v) {
        _vm.pnr = $$v;
      },
      expression: "pnr"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-body",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Chọn Hệ thống đặt vé",
      "label-for": "ticket-source"
    }
  }, [_c('v-select', {
    attrs: {
      "input-id": "ticket-source",
      "options": _vm.sourceOptions,
      "reduce": function reduce(val) {
        return val.value;
      },
      "label": "name",
      "placeholder": "Vui lòng chọn"
    },
    model: {
      value: _vm.ticketSource,
      callback: function callback($$v) {
        _vm.ticketSource = $$v;
      },
      expression: "ticketSource"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-body",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "email",
      "formatter": _vm.trimInput,
      "placeholder": "Nhập email..."
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('b-button', {
    staticClass: "px-3",
    attrs: {
      "variant": "gradient",
      "pill": "",
      "disabled": _vm.disableSearch
    },
    on: {
      "click": _vm.handleSendEmail
    }
  }, [_vm._v(" Gửi Email ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }