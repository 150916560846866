<template>
  <b-card>
    <h3 class="mb-50 fw-700">
      Gửi email vé 1S cũ
    </h3>
    <b-form ref="form">
      <b-row class="mx-0">
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nhập vào PNR"
            label-for="pnr"
          >
            <b-form-input
              id="pnr"
              v-model="pnr"
              :formatter="trimUpperCaseInput"
              placeholder="Nhập PNR..."
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="text-body"
        >
          <b-form-group
            label="Chọn Hệ thống đặt vé"
            label-for="ticket-source"
          >
            <v-select
              v-model="ticketSource"
              input-id="ticket-source"
              :options="sourceOptions"
              :reduce="val => val.value"
              label="name"
              placeholder="Vui lòng chọn"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="text-body"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              :formatter="trimInput"
              placeholder="Nhập email..."
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div class="d-flex-center gap-2">
      <b-button
        variant="gradient"
        pill
        class="px-3"
        :disabled="disableSearch"
        @click="handleSendEmail"
      >
        Gửi Email
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import { apiReservation } from '@/api'
import {
  EMAIL_REGEX,
} from '@/constants/selectOptions'

import { trimUpperCaseInput, trimInput } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BCol,
    BRow,

    vSelect,
  },

  setup() {
    const { toastSuccess, toastError } = useToast()
    const sourceOptions = ref([
      {
        name: 'Vietnam Airlines (1S)',
        value: 'VN1A',
      },
      {
        name: 'VNA Sabre Miền Trung (1S_CTRL)',
        value: 'VN1A_MT',
      },
    ])

    const pnr = ref('')
    const email = ref('')
    const ticketSource = ref('VN1A')

    const disableSearch = computed(() => !(pnr.value && ticketSource.value && EMAIL_REGEX.test(email.value)))

    async function handleSendEmail() {
      try {
        this.$bvModal.show('modal-api-loading')

        const payload = {
          source: ticketSource.value,
          pnrNumber: pnr.value,
          emailAddress: email.value,
        }

        await apiReservation.sendOutSideEmail(payload)
          .then(() => {
            toastSuccess({
              title: 'messagesList.success',
              content: 'Gửi email thành công',
            })
          })
          .catch(err => {
            console.error(err)
            toastError({
              title: 'messagesList.success',
              content: 'Gửi email thất bại, vui lòng kiểm tra lại thông tin!',
            })
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.$bvModal.hide('modal-api-loading')
      }
    }

    return {
      pnr,
      ticketSource,
      disableSearch,
      trimUpperCaseInput,
      trimInput,
      handleSendEmail,
      sourceOptions,
      email,
    }
  },
}
</script>
